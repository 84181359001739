import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

	constructor() {	}

	ngOnInit() {
		
	}

	logout(){
		localStorage.removeItem('token');
		window.location.href = '/login';
	}
}
