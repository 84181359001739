import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UserTypes} from '../interfaces/user-type';
import * as utilData from '../utils/data-configuration';

@Injectable({
	providedIn: 'root'
})
export class UsertypesService {

	headers:HttpHeaders;

	constructor(private httpClient:HttpClient) {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.headers = new HttpHeaders();
		this.headers = this.headers
		.set('Content-Type','application/json; charset=utf-8')
		.set('Authorization',"Bearer "+login.token);
	}

	getTypes() {
		return this.httpClient.get<UserTypes[]>(utilData.USER_TYPES_V1,{headers:this.headers});
	}
}