import { Component, OnInit, Injectable } from '@angular/core';
import {InstitutesService} from '../../services/institutes.service';
import { HttpClient } from '@angular/common/http';
import {Institutes} from '../../interfaces/institute';

import * as utilData from '../../utils/data-configuration';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-list-institute',
	templateUrl: './list-institute.component.html',
	styleUrls: ['./list-institute.component.css']
})
export class ListInstituteComponent implements OnInit {
	
	isReady:boolean=true;
	intitutes:Institutes[];
	isAdmin=false;

	constructor(private instituteService:InstitutesService) {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.isAdmin=login.user_type_id===utilFunctions.ADMIN
	}

	ngOnInit() {
		this.getAll();
	}

	getAll(){
		this.instituteService.getAll().subscribe((data:Institutes[])=>{
			this.intitutes=data;
			if (this.isReady) {
				this.isReady=false;
				utilFunctions.initDataTable();
			}
		});
	}

	deleteInstitute(id){
		if (confirm(utilFunctions.delete_question)) {
			this.instituteService.delete(id).subscribe(
				(success)=>{
					this.getAll();
				},
				(error)=>{
					alert(utilFunctions.error);
				}
				);	
		}
	}
}


