import { Component, OnInit } from '@angular/core';
import {UsertypesService} from '../../services/usertypes.service';
import {UsersService} from '../../services/users.service';
import {UserTypes} from '../../interfaces/user-type';
import {Users} from '../../interfaces/users';

import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as utilFunctions from '../../utils/utils';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  id:any;
  titleTab:string
  registerForm: FormGroup;
  userTypes:UserTypes[];
  submitted:boolean = false;
  editing:boolean = false;
  loading:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private usertypesService:UsertypesService,
    private usersService:UsersService,
    private activatedRoute:ActivatedRoute,
    private router:Router) { }

  ngOnInit() {

    this.id=this.activatedRoute.snapshot.params['id'];
    this.editing=this.id?true:false;
    this.titleTab=this.editing?"Editar":"Nuevo";

    this.registerForm = this.formBuilder.group({
      user_type_id: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      user: ['', Validators.required],
      password: ['', this.editing?'':Validators.required]
    });

    this.getUserTipes();

    if (this.editing) {
      this.getById(this.id);
    }
  }

  get f() { return this.registerForm.controls; }

  redirecToList(){
    this.router.navigate(['/users']);
  }

  getUserTipes(){
    this.usertypesService.getTypes().subscribe((data:UserTypes[])=>{
      this.userTypes=[data[0],data[1]];
      if (!this.editing) {
        this.registerForm.get('user_type_id').setValue(data[0].id);
      }
    });
  }

  getById(id){
    this.usersService.getById(id).subscribe(
      (response:Users)=>{
        this.registerForm.get('name').setValue(response.name);
        this.registerForm.get('phone').setValue(response.phone);
        this.registerForm.get('email').setValue(response.email);
        this.registerForm.get('user').setValue(response.user);
        this.registerForm.get('password').setValue(response.password);
        this.registerForm.get('user_type_id').setValue(response.user_type_id);
      },
      (error)=>{
        alert(utilFunctions.error);
      }
      );
  }

  saveUser(){
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading=true;
    if (this.editing) {
      this.usersService.update(this.registerForm.value,this.id).subscribe(
        (success)=>{
          this.redirecToList();
        },
        (error)=>{
          this.loading=false;
          alert("Usuario y/o Correo electrónico duplicado.");
        }
      );
    }else{
      console.log(this.registerForm.value);
      this.usersService.save(this.registerForm.value).subscribe(
        (success)=>{
          this.redirecToList();
        },
        (error)=>{
          this.loading=false;
          alert("Usuario y/o Correo electrónico duplicado.");
        }
      );
    }
  }
}

