import { Component, OnInit } from '@angular/core';
import {Login} from './interfaces/login';
import * as utilData from './utils/data-configuration';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'InglesIndividualAngular';
  isLogin = 1;

  ngOnInit() {
    //if(localStorage.getItem(utilData.PARAM_TOKEN)===null){
    if(localStorage.getItem(utilData.PARAM_TOKEN)===null){
      this.isLogin = 1;
    }else{
      //var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
      //alert(login.name);
      this.isLogin = 0;
    }
  }
}