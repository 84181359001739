import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Teachers} from '../interfaces/teachers';
import * as utilData from '../utils/data-configuration';

@Injectable({
	providedIn: 'root'
})
export class TeachersService {

	headers:HttpHeaders;
	
	constructor(private httpClient:HttpClient) {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.headers = new HttpHeaders();
		this.headers = this.headers
		.set('Content-Type','application/json; charset=utf-8')
		.set('Authorization',"Bearer "+login.token);
	}

	getAll() {
		return this.httpClient.get<Teachers[]>(utilData.TEACHERS_V1,{headers:this.headers});
	}

	save(teacher: Teachers) {
		return this.httpClient.post(utilData.TEACHERS_V1, teacher,{headers:this.headers});
	}

	getById(id: number) {
		return this.httpClient.get(utilData.TEACHERS_V1 +"/" + id,{headers:this.headers});
	}

	update(teacher: Teachers,id: number) {
		return this.httpClient.put(utilData.TEACHERS_V1 + "/" + id, teacher,{headers:this.headers});
	}

	delete(id: number) {
		return this.httpClient.delete(utilData.TEACHERS_V1 +"/" + id,{headers:this.headers});
	}
}