import { Component, OnInit } from '@angular/core';
import {UsersService} from '../../services/users.service';
import {Users} from '../../interfaces/users';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-list-users',
	templateUrl: './list-users.component.html',
	styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {

	isReady:boolean=true;
	users:Users[];

	constructor(private usersService:UsersService) { }

	ngOnInit() {
		this.getAll();
	}

	getAll(){
		this.usersService.getAll().subscribe((data:Users[])=>{
			console.log(data);
			this.users=data.filter(item=>item.user_type_id!=3);
			if (this.isReady) {
				this.isReady=false;
				utilFunctions.initDataTable();
			}
		});
	}

	deleteInstitute(id){
		if (confirm(utilFunctions.delete_question)) {
			this.usersService.delete(id).subscribe(
				(success)=>{
					this.getAll();
				},
				(error)=>{
					alert(utilFunctions.error);
				}
			);	
		}
	}
}
