const API_ENDPOINT:string = "http://165.22.186.2:81/api";
//const API_ENDPOINT:string = "http://127.0.0.1:8000/api";
export const PARAM_TOKEN:string= "token";

export const LOGIN_V1:string = API_ENDPOINT+"/v1/login";
export const INSTITUTES_V1:string = API_ENDPOINT+"/v1/institutes";
export const USERS_V1:string = API_ENDPOINT+"/v1/users";
export const STUDENTS_V1:string = API_ENDPOINT+"/v1/students";
export const USER_TYPES_V1:string = API_ENDPOINT+"/v1/usertypes";
export const USER_INSTITUTES_V1:string = API_ENDPOINT+"/v1/userinstitutes";

export const TEACHERS_V1:string = API_ENDPOINT+"/v1/teachers";