import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Students} from '../interfaces/students';
import * as utilData from '../utils/data-configuration';

@Injectable({
	providedIn: 'root'
})
export class StudentsService {

	headers:HttpHeaders;

	constructor(private httpClient:HttpClient) {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.headers = new HttpHeaders();
		this.headers = this.headers
		.set('Content-Type','application/json; charset=utf-8')
		.set('Authorization',"Bearer "+login.token);
	}

	getAll() {
		return this.httpClient.get<Students[]>(utilData.STUDENTS_V1,{headers:this.headers});
	}

	save(user: Students) {
		return this.httpClient.post(utilData.STUDENTS_V1, user,{headers:this.headers});
	}

	getById(id: number) {
		return this.httpClient.get(utilData.STUDENTS_V1 +"/" + id,{headers:this.headers});
	}

	update(user: Students,id: number) {
		return this.httpClient.put(utilData.STUDENTS_V1 + "/" + id, user,{headers:this.headers});
	}

	delete(id: number) {
		return this.httpClient.delete(utilData.STUDENTS_V1 +"/" + id,{headers:this.headers});
	}
}