import { Component, OnInit } from '@angular/core';
import {TeachersService} from '../../services/teachers.service';
import {InstitutesService} from '../../services/institutes.service';
import {Teachers} from '../../interfaces/teachers';
import {Institutes} from '../../interfaces/institute';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-add-teachers',
	templateUrl: './add-teachers.component.html',
	styleUrls: ['./add-teachers.component.css']
})
export class AddTeachersComponent implements OnInit {

	registerForm: FormGroup;

	titleTab:string
	id:any;
	loading:boolean = false;
	editing:boolean = false;
	submitted:boolean = false;
	institutes:Institutes[];

	constructor(
		private formBuilder: FormBuilder,
		private teachersService:TeachersService,
		private institutesService:InstitutesService,
		private activatedRoute:ActivatedRoute,
		private router:Router) { }

	ngOnInit() {
		this.id=this.activatedRoute.snapshot.params['id'];
		this.editing=this.id?true:false;
		this.titleTab=this.editing?"Editar":"Nuevo";

		this.registerForm = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', Validators.required],
			phone: ['', Validators.required],
			institute_id: ['', Validators.required]
		});

		this.getAllInstitutes();

		if (this.editing) {
			this.getById(this.id);
		}
	}

	get f() { return this.registerForm.controls; }

	redirecToList(){
		this.router.navigate(['/teachers']);
	}

	getById(id){
		this.teachersService.getById(id).subscribe(
			(response:Teachers)=>{
				this.registerForm.get('name').setValue(response.name);
				this.registerForm.get('email').setValue(response.email);
				this.registerForm.get('phone').setValue(response.phone);
				this.registerForm.get('institute_id').setValue(response.institute_id);
			},
			(error)=>{
				alert(utilFunctions.error);
			}
		);
	}
	getAllInstitutes(){
		this.institutesService.getAll().subscribe((data:Institutes[])=>{
			this.institutes=data;
			if (!this.editing) {
				this.registerForm.get('institute_id').setValue(data[0].id);
			}
		});
	}

	saveTeacher(){
		this.submitted = true;
		if (this.registerForm.invalid) {
			return;
		}
		this.loading=true;
		if (this.editing) {
			this.teachersService.update(this.registerForm.value,this.id).subscribe(
				(success)=>{
					this.redirecToList();
				},
				(error)=>{
					this.loading=false;
					alert(utilFunctions.error);
				}
			);
		}else{
			this.teachersService.save(this.registerForm.value).subscribe(
				(success)=>{
					this.redirecToList();
				},
				(error)=>{
					this.loading=false;
					alert(utilFunctions.error);
				}
			);
		}
	}
}

