import { Component, OnInit } from '@angular/core';
import {InstitutesService} from '../../services/institutes.service';
import {Institutes} from '../../interfaces/institute';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-add-institute',
	templateUrl: './add-institute.component.html',
	styleUrls: ['./add-institute.component.css']
})
export class AddInstituteComponent implements OnInit {

	registerForm: FormGroup;

	titleTab:string
	id:any;
	loading:boolean = false;
	editing:boolean = false;
	submitted:boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private instituteService:InstitutesService,
		private activatedRoute:ActivatedRoute,
		private router:Router) { }

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			name: ['', Validators.required],
			address: ['', Validators.required],
			phone: ['', Validators.required]
		});

		this.id=this.activatedRoute.snapshot.params['id'];
		if (this.id) {
			this.titleTab="Editar";
			this.editing=true;
			this.getById(this.id);
		}else{
			this.titleTab="Nuevo";
			this.editing=false;
		}
	}

	get f() { return this.registerForm.controls; }

	redirecToList(){
		this.router.navigate(['/institutes']);
	}

	getById(id){
		this.instituteService.getById(id).subscribe(
			(response:Institutes)=>{
				this.registerForm.get('name').setValue(response.name);
				this.registerForm.get('address').setValue(response.address);
				this.registerForm.get('phone').setValue(response.phone);
			},
			(error)=>{
				alert(utilFunctions.error);
			}
		);
	}

	saveInstitute(){
		this.submitted = true;
		if (this.registerForm.invalid) {
			return;
		}
		this.loading=true;
		if (this.editing) {
			this.instituteService.update(this.registerForm.value,this.id).subscribe(
				(success)=>{
					this.redirecToList();
				},
				(error)=>{
					this.loading=false;
					alert(utilFunctions.error);
				}
			);
		}else{
			this.instituteService.save(this.registerForm.value).subscribe(
				(success)=>{
					this.redirecToList();
				},
				(error)=>{
					this.loading=false;
					alert(utilFunctions.error);
				}
			);
		}
	}
}

