import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';

import { AddUserComponent } from './users/add-user/add-user.component';
import { ListUsersComponent } from './users/list-users/list-users.component';

import { AddInstituteComponent } from './institutes/add-institute/add-institute.component';
import { ListInstituteComponent } from './institutes/list-institute/list-institute.component';

import { AddStudentComponent } from './students/add-student/add-student.component';
import { ListStudentComponent } from './students/list-student/list-student.component';

import { LoginComponent } from './login/login.component';

import { AddUserInstituteComponent } from './userinstitutes/add-user-institute/add-user-institute.component';
import { ListUserInstitutesComponent } from './userinstitutes/list-user-institutes/list-user-institutes.component';

import { AddTeachersComponent } from './teachers/add-teachers/add-teachers.component';
import { ListTeachersComponent } from './teachers/list-teachers/list-teachers.component';

const routes: Routes = [
	{path: 'login', component: LoginComponent},
	
	{path: '', component: HomeComponent},

	{path: 'institutes', component: ListInstituteComponent},
	{path: 'institutes/edit/:id', component: AddInstituteComponent},
	{path: 'institutes/new', component: AddInstituteComponent},

	{path: 'users', component: ListUsersComponent},
	{path: 'users/new', component: AddUserComponent},
	{path: 'users/edit/:id', component: AddUserComponent},

	{path: 'students', component: ListStudentComponent},
	{path: 'students/edit/:id', component: AddStudentComponent},
	{path: 'students/new', component: AddStudentComponent},

	{path: 'userinstitutes/:id', component: ListUserInstitutesComponent},
	{path: 'userinstitutes/new/:id', component: AddUserInstituteComponent},

	{path: 'teachers', component: ListTeachersComponent},
	{path: 'teachers/new', component: AddTeachersComponent},
	{path: 'teachers/edit/:id', component: AddTeachersComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [ HomeComponent,
ListUsersComponent, AddUserComponent,
ListInstituteComponent ,AddInstituteComponent,
ListStudentComponent,AddStudentComponent,
ListUserInstitutesComponent, AddUserInstituteComponent,
ListTeachersComponent,AddTeachersComponent,
LoginComponent]

