import { Component, OnInit } from '@angular/core';
import {Institutes} from '../../interfaces/institute';
import {InstitutesService} from '../../services/institutes.service';
import {UserinstitutesService} from '../../services/userinstitutes.service';
import {UserInstitutes} from '../../interfaces/user-institutes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import * as utilFunctions from '../../utils/utils';
import {UsersService} from '../../services/users.service';
import {Users} from '../../interfaces/users'

@Component({
	selector: 'app-add-user-institute',
	templateUrl: './add-user-institute.component.html',
	styleUrls: ['./add-user-institute.component.css']
})
export class AddUserInstituteComponent implements OnInit {

	idUser:any;
	institutes:Institutes[];
	users:Users;
	registerForm: FormGroup;
	submitted:boolean = false;
	loading:boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private institutesService:InstitutesService,
		private userinstitutesService:UserinstitutesService,
		private usersService:UsersService,
		private activatedRoute:ActivatedRoute,
		private router:Router) { }

	ngOnInit() {
		this.idUser=this.activatedRoute.snapshot.params['id'];

		this.registerForm = this.formBuilder.group({
			user_id: ['', Validators.required],
			institute_id: ['', Validators.required],
		});

		this.registerForm.get('user_id').setValue(this.idUser);

		this.getAllInstitutes();
		this.getUserDetail();
	}

	get f() { return this.registerForm.controls; }

	redirecToList(){
		this.router.navigate(['/userinstitutes/'+this.idUser]);
	}

	getUserDetail(){
		this.usersService.getAll().subscribe((data:Users[])=>{
			console.log(data);
			this.users=data.find(item=>item.id==this.idUser);
		});
	}

	getAllInstitutes(){
		this.institutesService.getAll().subscribe((data:Institutes[])=>{
			this.institutes=data;
			this.registerForm.get('institute_id').setValue(data[0].id);
		});
	}

	saveInstitute(){
		this.submitted = true;
		if (this.registerForm.invalid) {
			return;
		}
		console.log(this.registerForm.value);
		this.userinstitutesService.save(this.registerForm.value).subscribe(
			(success)=>{
				this.redirecToList();
			},
			(error)=>{
				this.loading=false;
				alert(utilFunctions.error);
			}
		);
	}
}