import { Component, OnInit } from '@angular/core';
import {StudentsService} from '../../services/students.service';
import {Students} from '../../interfaces/students';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-list-student',
	templateUrl: './list-student.component.html',
	styleUrls: ['./list-student.component.css']
})
export class ListStudentComponent implements OnInit {

	isReady:boolean=true;
	students:Students[];

	constructor(private studentsService:StudentsService) { }

	ngOnInit() {
		this.getAll();
	}

	getAll(){
		this.studentsService.getAll().subscribe((data:Students[])=>{
			console.log(data);
			this.students=data;
			if (this.isReady) {
				this.isReady=false;
				utilFunctions.initDataTable();
			}
		});
	}

	deleteStudent(id){
		if (confirm(utilFunctions.delete_question)) {
			this.studentsService.delete(id).subscribe(
				(success)=>{
					this.getAll();
				},
				(error)=>{
					alert(utilFunctions.error);
				}
			);	
		}
	}
}
