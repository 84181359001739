import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {TeachersService} from '../../services/teachers.service';
import {Teachers} from '../../interfaces/teachers';

import * as utilData from '../../utils/data-configuration';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-list-teachers',
	templateUrl: './list-teachers.component.html',
	styleUrls: ['./list-teachers.component.css']
})
export class ListTeachersComponent implements OnInit {
	
	isReady:boolean=true;
	teachers:Teachers[];
	isAdmin=false;

	constructor(private teachersService:TeachersService) {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.isAdmin=login.user_type_id===utilFunctions.ADMIN
	}

	ngOnInit() {
		this.getAll();
	}

	getAll(){
		this.teachersService.getAll().subscribe((data:Teachers[])=>{
			this.teachers=data;
			if (this.isReady) {
				this.isReady=false;
				utilFunctions.initDataTable();
			}
		});
	}

	deleteTeacher(id){
		if (confirm(utilFunctions.delete_question)) {
			this.teachersService.delete(id).subscribe(
				(success)=>{
					this.getAll();
				},
				(error)=>{
					alert(utilFunctions.error);
				}
			);	
		}
	}
}


