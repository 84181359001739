import { Component, OnInit } from '@angular/core';
import {StudentsService} from '../../services/students.service';
import {InstitutesService} from '../../services/institutes.service';
import {Students} from '../../interfaces/students';
import {Institutes} from '../../interfaces/institute';
import * as utilFunctions from '../../utils/utils';

import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {

  id:any;
  titleTab:string
  registerForm: FormGroup;
  submitted:boolean = false;
  editing:boolean = false;
  loading:boolean = false;
  institutes:Institutes[];

  constructor(
    private formBuilder: FormBuilder,
    private studentsService:StudentsService,
    private institutesService:InstitutesService,
    private activatedRoute:ActivatedRoute,
    private router:Router) { }

  ngOnInit() {
    this.id=this.activatedRoute.snapshot.params['id'];
    this.editing=this.id?true:false;
    this.titleTab=this.editing?"Editar":"Nuevo";

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      address: ['', Validators.required],
      birthdate: ['', Validators.required],
      age: ['', Validators.required],
      user: ['', Validators.required],
      password: ['', this.editing?'':Validators.required],
      institute_id: ['', Validators.required],
    });

    this.getAllInstitutes();

    if (this.editing) {
      this.getById(this.id);
    }else{
      initDocumentReady();
    }
  }

  get f() { return this.registerForm.controls; }

  redirecToList(){
    this.router.navigate(['/students']);
  }

  getAllInstitutes(){
    this.institutesService.getAll().subscribe((data:Institutes[])=>{
      this.institutes=data;
      if (!this.editing) {
        this.registerForm.get('institute_id').setValue(data[0].id);
      }
    });
  }

  getById(id){
    this.studentsService.getById(id).subscribe(
      (response:Students)=>{
        this.registerForm.get('name').setValue(response.name);
        this.registerForm.get('phone').setValue(response.phone);
        this.registerForm.get('email').setValue(response.email);
        this.registerForm.get('address').setValue(response.address);
        this.registerForm.get('birthdate').setValue(response.birthdate);
        this.registerForm.get('age').setValue(response.age);
        this.registerForm.get('user').setValue(response.user);
        this.registerForm.get('institute_id').setValue(response.institute_id);
        initDocumentReady();
      },
      (error)=>{
        alert(utilFunctions.error);
      }
    );
  }

  saveStudent(){
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading=true;
    if (this.editing) {
      this.studentsService.update(this.registerForm.value,this.id).subscribe(
        (success)=>{
          this.redirecToList();
        },
        (error)=>{
          this.loading=false;
          alert("Usuario y/o Correo electrónico duplicado.");
        }
      );
    }else{
      console.log(this.registerForm.value);
      this.studentsService.save(this.registerForm.value).subscribe(
        (success)=>{
          this.redirecToList();
        },
        (error)=>{
          this.loading=false;
          alert("Usuario y/o Correo electrónico duplicado.");
        }
      );
    }
  }
}

function initDocumentReady(){
	$(document).ready(() => {
		//$('#birthdate').inputmask('dd/mm/yyyy', { 'placeholder': 'dd/mm/yyyy' })
	});
}
