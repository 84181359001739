import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { HeaderComponent } from './shared/header/header.component';
import { MenuComponent } from './shared/menu/menu.component';
import { ContentComponent } from './shared/content/content.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SettingsComponent } from './shared/settings/settings.component';

import { HomeComponent } from './home/home.component';

import { AddUserComponent } from './users/add-user/add-user.component';
import { ListUsersComponent } from './users/list-users/list-users.component';

import { AddInstituteComponent } from './institutes/add-institute/add-institute.component';
import { ListInstituteComponent } from './institutes/list-institute/list-institute.component';

import { AddStudentComponent } from './students/add-student/add-student.component';
import { ListStudentComponent } from './students/list-student/list-student.component';

import { LoginComponent } from './login/login.component';

import { AddUserInstituteComponent } from './userinstitutes/add-user-institute/add-user-institute.component';
import { ListUserInstitutesComponent } from './userinstitutes/list-user-institutes/list-user-institutes.component';

import {HttpErrorInterceptor} from './services/http-error.interceptor';

import { ListTeachersComponent } from './teachers/list-teachers/list-teachers.component';
import { AddTeachersComponent } from './teachers/add-teachers/add-teachers.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    ContentComponent,
    FooterComponent,
    SettingsComponent,
    HomeComponent,

    AddUserComponent,
    ListUsersComponent,
    
    AddInstituteComponent,
    ListInstituteComponent,
    
    AddStudentComponent,
    ListStudentComponent,
    LoginComponent,
    AddUserInstituteComponent,
    ListUserInstitutesComponent,

    ListTeachersComponent,
    AddTeachersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
