import { Component, OnInit } from '@angular/core';
import {UserinstitutesService} from '../../services/userinstitutes.service';
import {UserInstitutes} from '../../interfaces/user-institutes';
import {UsersService} from '../../services/users.service';
import {Users} from '../../interfaces/users';
import * as utilFunctions from '../../utils/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-user-institutes',
  templateUrl: './list-user-institutes.component.html',
  styleUrls: ['./list-user-institutes.component.css']
})
export class ListUserInstitutesComponent implements OnInit {

  idUser:any;
  institutes:UserInstitutes[];
  users:Users;

  constructor(
    private userinstitutesService:UserinstitutesService,
    private usersService:UsersService,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this.idUser=this.activatedRoute.snapshot.params['id'];
  	this.getAll();
    this.getUserDetail();
  }

  getAll(){
  	this.userinstitutesService.getAll(this.idUser).subscribe((data:UserInstitutes[])=>{
  		this.institutes=data;
  	});
  }

  getUserDetail(){
    this.usersService.getAll().subscribe((data:Users[])=>{
      console.log(data);
      this.users=data.find(item=>item.id==this.idUser);
      
    });
  }

  deleteInstitute(id){
    if (confirm(utilFunctions.delete_question)) {
      this.userinstitutesService.delete(id).subscribe(
        (success)=>{
          this.getAll();
        },
        (error)=>{
          alert(utilFunctions.error);
        }
      );  
    }
  }
}