declare var $: any;

export const delete_question:string= "¿Seguro que deseas eliminar el registro?.";
export const error:string= "Ocurrió un error, intente más tarde.";
export const error_login:string= "Usuario y/o contraseña incorrecta.";
export const ADMIN:number =1;
export const INSTITUTE:number = 2;
export const STUDENT:number = 3;

export function initDataTable(){
	$(document).ready(() => {
		$('#tableList').DataTable({
			'bSortable': false,
			'paging'      : true,
			'lengthChange': true,
			'searching'   : true,
			'ordering'    : false,
			'info'        : true,
			'autoWidth'   : true,
			'aLengthMenu': [10, 25, 50, 100],
			'oLanguage':{
				'sSearch':'Buscar:',
				'sInfo': 'Mostrando _START_ al _END_ de _TOTAL_ registros',
				'sInfoEmpty': 'Mostrando 0 al _END_ de _TOTAL_ registros',
				'sZeroRecords': 'No se encontraron registros coincidentes.',
				'sInfoFiltered':'',
				'sLengthMenu': 'Mostrar _MENU_',
				'oPaginate' : {
					'sNext' : 'Siguiente',
					'sPrevious' : 'Atras'
				}
			}
		});
	});
}