import { Component, OnInit } from '@angular/core';
import {UsersService} from '../services/users.service';
import {Users} from '../interfaces/users';
import {Login} from '../interfaces/login';

import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as utilFunctions from '../utils/utils';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	loginModel:Login
	submitted:boolean = false;
	loading:boolean = false;
	urlRetorno:String;

	constructor(
		private formBuilder: FormBuilder,
		private usersService:UsersService,
		private activatedRoute:ActivatedRoute,
		private router:Router
		) { }

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			user: ['', Validators.required],
			password: ['', Validators.required],
		});
	}

	get f() { return this.loginForm.controls; }

	redirecToHome(){
		window.location.href = '/';
	}

	login(){

		this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		}

		this.usersService.login(this.loginForm.get('user').value,this.loginForm.get('password').value).subscribe(
			(response:Login)=>{
				if (response.user_type_id!=utilFunctions.STUDENT) {
					localStorage.setItem("token",JSON.stringify(response));
					this.redirecToHome();
				}else{
					alert(utilFunctions.error_login);
				}
			},
			(error)=>{
				this.loading=false;
				alert(utilFunctions.error_login);
			}
			);
	}
}

