import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Users} from '../interfaces/users';
import {Login} from '../interfaces/login';
import * as utilData from '../utils/data-configuration';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	headers:HttpHeaders;

	constructor(private httpClient:HttpClient) { 
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		if(login!=null){
			this.headers = new HttpHeaders();
			this.headers = this.headers
			.set('Content-Type','application/json; charset=utf-8')
			.set('Authorization',"Bearer "+login.token);
		}
	}

	login(user, password){
		var  h = new HttpHeaders();
		h = h
		.set('Content-Type','application/json; charset=utf-8');
		return this.httpClient.post(utilData.LOGIN_V1, user,{headers:h,params:{user:user,password:password}});
	}

	getAll() {
		return this.httpClient.get<Users[]>(utilData.USERS_V1,{headers:this.headers});
	}

	save(user: Users) {
		return this.httpClient.post(utilData.USERS_V1, user,{headers:this.headers});
	}

	getById(id: number) {
		return this.httpClient.get(utilData.USERS_V1 +"/" + id,{headers:this.headers});
	}

	update(user: Users,id: number) {
		return this.httpClient.put(utilData.USERS_V1 + "/" + id, user,{headers:this.headers});
	}

	delete(id: number) {
		return this.httpClient.delete(utilData.USERS_V1 +"/" + id,{headers:this.headers});
	}
}