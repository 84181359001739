import { Component, OnInit } from '@angular/core';
import * as utilData from '../../utils/data-configuration';
import * as utilFunctions from '../../utils/utils';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

	name:String
	isAdmin:boolean=false

	constructor() { }

	ngOnInit() {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.name=  login.name;
		if(login.user_type_id===utilFunctions.ADMIN){
			this.isAdmin=true;
		}
	}
}
