import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UserInstitutes} from '../interfaces/user-institutes';
import * as utilData from '../utils/data-configuration';

@Injectable({
	providedIn: 'root'
})
export class UserinstitutesService {

	headers:HttpHeaders;

	constructor(private httpClient:HttpClient) {
		var login = JSON.parse(localStorage.getItem(utilData.PARAM_TOKEN));
		this.headers = new HttpHeaders();
		this.headers = this.headers
		.set('Content-Type','application/json; charset=utf-8')
		.set('Authorization',"Bearer "+login.token);
	}

	getAll(idUser: number) {
		return this.httpClient.get<UserInstitutes[]>(utilData.USER_INSTITUTES_V1 + "/" + idUser,{headers:this.headers});
	}

	save(user: UserInstitutes) {
		return this.httpClient.post(utilData.USER_INSTITUTES_V1, user,{headers:this.headers});
	}

	delete(id: number) {
		return this.httpClient.delete(utilData.USER_INSTITUTES_V1 +"/" + id,{headers:this.headers});
	}
}